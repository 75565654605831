exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-mdx": () => import("./../../../src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-appearances-mdx": () => import("./../../../src/pages/appearances.mdx" /* webpackChunkName: "component---src-pages-appearances-mdx" */),
  "component---src-pages-art-3-d-mdx": () => import("./../../../src/pages/art/3d.mdx" /* webpackChunkName: "component---src-pages-art-3-d-mdx" */),
  "component---src-pages-art-design-mdx": () => import("./../../../src/pages/art/design.mdx" /* webpackChunkName: "component---src-pages-art-design-mdx" */),
  "component---src-pages-art-index-mdx": () => import("./../../../src/pages/art/index.mdx" /* webpackChunkName: "component---src-pages-art-index-mdx" */),
  "component---src-pages-art-photography-mdx": () => import("./../../../src/pages/art/photography.mdx" /* webpackChunkName: "component---src-pages-art-photography-mdx" */),
  "component---src-pages-category-name-tsx": () => import("./../../../src/pages/{Category.name}.tsx" /* webpackChunkName: "component---src-pages-category-name-tsx" */),
  "component---src-pages-garden-tsx": () => import("./../../../src/pages/garden.tsx" /* webpackChunkName: "component---src-pages-garden-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-notice-mdx": () => import("./../../../src/pages/legal-notice.mdx" /* webpackChunkName: "component---src-pages-legal-notice-mdx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-projects-mdx": () => import("./../../../src/pages/projects.mdx" /* webpackChunkName: "component---src-pages-projects-mdx" */),
  "component---src-pages-tutorials-tsx": () => import("./../../../src/pages/tutorials.tsx" /* webpackChunkName: "component---src-pages-tutorials-tsx" */),
  "component---src-pages-uses-mdx": () => import("./../../../src/pages/uses.mdx" /* webpackChunkName: "component---src-pages-uses-mdx" */),
  "component---src-pages-writing-tsx": () => import("./../../../src/pages/writing.tsx" /* webpackChunkName: "component---src-pages-writing-tsx" */),
  "component---src-templates-garden-tsx": () => import("./../../../src/templates/garden.tsx" /* webpackChunkName: "component---src-templates-garden-tsx" */),
  "component---src-templates-prose-tsx": () => import("./../../../src/templates/prose.tsx" /* webpackChunkName: "component---src-templates-prose-tsx" */),
  "component---src-templates-tutorial-tsx": () => import("./../../../src/templates/tutorial.tsx" /* webpackChunkName: "component---src-templates-tutorial-tsx" */)
}

